import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import DeviceDetector from "./utils/DeviceDetector";

const Header = lazy(() => import("./structures/Header"));
const Footer = lazy(() => import("./structures/Footer"));
const AppRoutes = lazy(() => import("./AppRoutes"));

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle touch swipe to open/close the sidebar
  useEffect(() => {
    const handleTouchStart = (e) => {
      setTouchStart(e.touches[0].clientX); // Capture the start point of the touch
    };
    const handleTouchEnd = (e) => {
      const touchEnd = e.changedTouches[0].clientX; // Capture the end point of the touch
      if (touchStart !== null && !isSidebarOpen) {
        // Only activate swipe when sidebar is closed
        // Swipe right to open
        if (touchStart - touchEnd < -100) {
          const storedState = sessionStorage.getItem("headerOpen");
          if (storedState === "true") {
            return;
          }
          setIsSidebarOpen(true);
        }
      } else if (touchStart !== null && isSidebarOpen) {
        // Only activate swipe when sidebar is open
        // Swipe left to close
        if (touchStart - touchEnd > 100) {
          const storedState = sessionStorage.getItem("headerOpen");
          if (storedState === "true") {
            return;
          }
          setIsSidebarOpen(false);
        }
      }
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [touchStart, isSidebarOpen]);

  if (DeviceDetector.isApple() && DeviceDetector.isMobile()) {
    return (
      <div className="relative flex flex-col min-h-screen">
        <div className="bg-animated backdrop-blur-lg">
          <div className="container-box-bg"></div>
          <Header toggleSidebar={toggleSidebar} />
          <div className="grid grid-cols-1 md:grid-cols-[250px_1fr] min-h-screen relative">
            <Sidebar isOpen={isSidebarOpen} />
            <div className="p-4 pt-[64px]">
              <div className="container-box-bg">
                {" "}
                <div className="container-box container-box-1 rounded-2xl blur-3xl background-blur"></div>
                <div className="container-box container-box-1 rounded-2xl scale-120"></div>
                <div className="container-box container-box-2 rounded-2xl blur-3xl background-blur"></div>
                <div className="container-box container-box-2 rounded-2xl scale-130"></div>
                <div className="container-box container-box-3 rounded-2xl blur-3xl background-blur"></div>
                <div className="container-box container-box-3 rounded-2xl scale-140"></div>
                <div className="container-box container-box-4 rounded-2xl blur-3xl background-blur"></div>
                <div className="container-box container-box-4 rounded-2xl"></div>
              </div>
              <AppRoutes />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div className="relative flex flex-col min-h-screen">
      <div className="bg-animated backdrop-blur-lg">
        <div className="container-box-bg"></div>
        <Header toggleSidebar={toggleSidebar} />
        <div className="grid grid-cols-1 md:grid-cols-[250px_1fr] min-h-screen relative">
          <Sidebar isOpen={isSidebarOpen} />
          <div className="p-4 pt-[64px]">
            <div className="container-box-bg">
              {" "}
              <div className="container-box container-box-1 rounded-2xl blur-3xl background-blur"></div>
              <div className="container-box container-box-1 rounded-2xl scale-120"></div>
              <div className="container-box container-box-2 rounded-2xl blur-3xl background-blur"></div>
              <div className="container-box container-box-2 rounded-2xl scale-130"></div>
              <div className="container-box container-box-3 rounded-2xl blur-3xl background-blur"></div>
              <div className="container-box container-box-3 rounded-2xl scale-140"></div>
              <div className="container-box container-box-4 rounded-2xl blur-3xl background-blur"></div>
              <div className="container-box container-box-4 rounded-2xl"></div>
            </div>
            <AnimatePresence mode="wait">
              <AppRoutes />
            </AnimatePresence>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

function Sidebar({ isOpen }) {
  const location = useLocation();

  // Definiere die Sidebar-Inhalte basierend auf der Route
  const getSidebarLinks = () => {
    const links = [
      { name: "Home", path: "/" },
      { name: "About", path: "/about" },
      { name: "Services", path: "/services" },
      { name: "Settings", path: "/settings" },
    ];

    if (location.pathname === "/") {
      return [
        { name: "Home", path: "/" },
        { name: "About", path: "#about" },
        { name: "Services", path: "#services" },
        { name: "Contact", path: "#contact" },
      ];
    }
    if (location.pathname === "/about") {
      return [
        { name: "About", path: "/about" },
        { name: "Philosophy", path: "/about#philosophy" },
        { name: "Why OG", path: "/about#why-og" },
      ];
    }

    if (location.pathname === "/services") {
      return [{ name: "Services", path: "/services" }];
    }

    if (location.pathname === "/contact") {
      return [{ name: "Contact", path: "/contact" }];
    }

    if (location.pathname === "/settings") {
      return [{ name: "Settings", path: "/settings" }];
    }

    return links; // Standardlinks zurückgeben
  };

  return (
    <div
      className={`md:relative h-full w-64 bg-black bg-opacity-50 backdrop-blur-md text-white transform transition-transform duration-300 ease-in-out z-10 md:translate-x-0
      ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } fixed top-0 left-0 h-full`}
    >
      <div className="sticky top-[64px] mt-4 p-4 text-gray-300">
        {getSidebarLinks().map((site) => (
          <Link
            to={site.path}
            key={site.path}
            className="block px-4 py-2 mx-2 text-sm font-semibold transition rounded-md hover:text-white hover:bg-gray-100 hover:bg-opacity-10"
          >
            {site.name}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default App;
