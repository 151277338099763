class DeviceDetector {
  static isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  static isTablet() {
    return /iPad/i.test(navigator.userAgent);
  }

  static isDesktop() {
    return !this.isMobile() && !this.isTablet();
  }
  static isApple() {
    return /iPhone|iPad|iPod|Macintosh|MacIntel|MacPPC|Mac68K/i.test(
      navigator.userAgent
    );
  }
  static isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }
  static isWindows() {
    return /Windows/i.test(navigator.userAgent);
  }
  static isLinux() {
    return /Linux/i.test(navigator.userAgent);
  }
}

export default DeviceDetector;
